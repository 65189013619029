<template>
    <div class="wrapper" >
      <div class="register">
            <div class="register_box">
                <div class="title"><b>R E G I S T E R</b></div>
                <el-form :model="user" :rules="rules" ref="userForm">
                    <el-form-item prop="username">
                        <el-input placeholder="account" size="medium" class="m2_0" prefix-icon="el-icon-user" v-model="user.username"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input placeholder="password" size="medium" class="m2_0" prefix-icon="el-icon-lock" show-password v-model="user.password"></el-input>
                    </el-form-item>
                    <el-form-item prop="confirmPassword">
                        <el-input placeholder="confirm password" size="medium" class="m2_0" prefix-icon="el-icon-lock" show-password v-model="user.confirmPassword"></el-input>
                    </el-form-item>
                    <el-form-item style="margin: 10px 0; text-align: right">
                        <el-button type="warning" size="small"  autocomplete="off" @click="register">REGISTER</el-button>
                        <!--<el-button type="primary" size="small"  autocomplete="off" @click="$router.push('/login')">返回登录</el-button>-->
                    </el-form-item>
                </el-form>
            </div>
      </div>
    </div>
</template>

<script>
import {setRoutes} from "@/router";

export default {
  name: "Login",
  data() {
    return {
      user: {},
      rules: {
        username: [
          { required: true, message: 'Please enter a username', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please enter your password', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: 'Please confirm your password', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    register() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {  // 表单校验合法
          if (this.user.password !== this.user.confirmPassword){
            this.$message.error("The password entered twice is inconsistent！")
            return false
          }
          this.request.post("/user/register", this.user).then(res => {
            if(res.code === '200') {
              this.$message.success("Registration Successful! Please log in！")
              this.$router.push('/login')
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
  login() {
      this.$refs['userForm'].validate((valid) => {
          if (valid) {  // 表单校验合法
              this.request.post("/user/login", this.user).then(res => {
                  if(res.code === '200') {
                      localStorage.setItem("user",JSON.stringify(res.data)) //存储用户信息到浏览器
                      localStorage.setItem("menus",JSON.stringify(res.data.menus))
                      //动态设置当前用户路由
                      setRoutes()
                      this.$message.success("Login successful!")

                      /*if (res.data.role === 'ROLE_USER') {
                        this.$router.push("/front/home")
                      }else {
                        this.$router.push("/")
                      }*/
                      this.$router.push("/")
                  } else {
                      this.$message.error(res.msg)
                  }
              })
          } else {
              return false;
          }
      });
  }
  }
}
</script>

<style >
.wrapper {
    background: #000;
    width:100%;
    height:100%;
    position:fixed;
    background-size:100% 120%;
}
.register {
  margin: 175px auto; 
  background: white; 
  width: 400px;
  height: 300px; 
  border-radius: 10px; 
  overflow: hidden
}
.register_box {
  margin: 5px auto; 
  background-color: #fff; 
  width: 300px; 
  height: 350px; 
  padding: 20px; 
  border-radius: 10px
}
.title {
  margin: 5px 0; 
  margin-bottom: 15px;
  text-align: center; 
  font-size: 24px
}
.m2_0 {
  margin: 2px 0
}
</style>
